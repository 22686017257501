html{
  /* ont: 14px "Century Gothic", Futura, sans-serif; */
  /* margin: 20px; */
  /* margin: 0; */
    height: 100%;
  /* background-color: #300030; For browsers that do not support gradients */
  /* background-image: url(http://www.transparenttextures.com/patterns/old-map.png); */
  /* background-image: linear-gradient(to bottom right, #42275a, #734b6d);  */
  /* background-image:linear-gradient(to bottom right, #2C3E50, #FD746C); */
  background-color: #F57F17;
background-image: url(/static/media/dark-wood.e3577ddd.png);
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  
}
body {
  font: 20px Comfortaa, cursive;
  
  margin: 0 20px;
  background-color: transparent;
  color: #444444;
  height: 100%;

  /* background-color: red; For browsers that do not support gradients */
  /* background-image: linear-gradient(to bottom right, red, yellow); Standard syntax (must be last) */
}
#root{
  height: 100%;
}
.option-container{
  padding: 0.125em;
}
.option{                                    /* TODO: make relative */
  padding: 20px;
  border: solid #444444 1px;
  /* color: white; */
  border-radius: 40px;
  /* margin :0.125em; */
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* background-color: #990066; */
  /* background-image: linear-gradient(to bottom right, #990066, #603090);  */
  /* background-image:linear-gradient(to bottom right, #2C3E50, #FD746C); */

}

.option.option-grey, .option.option-grey:hover{
  background-color: transparent;
  background-image: none;
  border: solid #999999 0.0625em;
  color: #999999;
  box-shadow: none;
}
.option:hover{
  /* border: solid #000000 0.125em; */
  /* margin: -0.0625em; */
  border: solid #000000 0.0625em;
  color: black;
  box-shadow:0.0125em 0.0125em 0.0625em #200020;
}
.option:active{
  background-color:  #FFFDE7;
}
.answers{
  /* padding: 0.0625em; */
  margin-bottom: 15px;
}
.h-100{
  height: 100%;
}
.question{
  /* border: solid white 2px; */
  /* border-radius: 4px; */
  padding:0.625em;
  margin : 0.125em;
}
.min-50{
  min-height: 50vh;
}
.game-box{
  /* min-height: 50vh; */
  /* border: solid white 2px; */
  border-radius: 0.25em;
  padding:0.625em;
  background-color:  #ffffff;
  /* background-image: linear-gradient(to bottom right, #f12711, #f5af19);  */
  /* background-image:linear-gradient(to bottom right, #2C3E50, #FD746C); */
  box-shadow:0.125em 0.125em 0.625em #5c2b00;
}
.aligner{
  vertical-align: middle;
}
.user-list-in-lobby{
  max-height: 50vh;
  overflow: scroll;
}
.option-row{
  background-color: transparent;
  /* width: 100%; */
  margin: auto;
}
.wrapper-class{
  border: 1px solid #dddddd;
  margin-bottom: 7px;
}
.editor-class{
  background-color: white;
}
table{
  width: 100%;
}
td{
  overflow: hidden;
}
.leader-box{
  height:100vh;
  background-color: white;
  border-left: 2px solid black; 
}

.y-button, .y-button.y-button-outline:focus{
  color: #5c2b00;
  border: 1px solid #5c2b00;
  background-color: #fff3cd;

}
.y-button.y-button-outline{
  background-color: #ffffff;
}

.btn{
  box-shadow: none !important;
}
.form-control.input{
  border-color: #a48b76 !important;
}
.form-control:focus {
  border-color: #a48b76 !important;
  outline: 0;
  box-shadow: none !important;;
}
.top-margin{
  margin-top: 7px;
}
.progress{
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: #ffffff;
  border: 1px solid #a48b76;
}
.progress-bar{
  background-color: #fff3cd;
}


